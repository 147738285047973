import React from "react";
import Ample from "../components/ample/ample";
import {useSiteMetadata} from "../branding";

const DisclaimerPage = (props) => {
    const siteMetadata = useSiteMetadata();
    return (
        <Ample currPageTitle="Disclaimer" seoDescription="Disclaimer" location={props.location.href}>
        <div className="white-box">
        Trading or investing whether on margin or otherwise carries a high level of risk.  It is possible to sustain a loss of some, or even more than, your initial investment.
        <br/><br/>
        {siteMetadata.author} is providing this site for educational purposes only.  It should not be considered legal, accounting, or financial advice. It is not a solicitation or recommendation to buy or sell any stocks, options, or other financial  investments.
        <br/><br/>
        Use of the information on this website or materials linked from the web is at your own risk. You should be aware of all the risks associated with trading and investing, and seek advice from an independent financial advisor before deciding to trade or invest.                </div>
      </Ample>);
}

export default DisclaimerPage;